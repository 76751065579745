<template>
    <o-data-lookup :data-object="dsRisks" contextField="OrgUnitIdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">
                <slot :target="target" />
            </span>
        </template>
        <o-column field="Name" width="350"></o-column>        
        <o-column field="Status" width="120"></o-column>
        <o-column field="OrgUnit" width="300"></o-column>
        <o-column field="Type" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsRisks = $getDataObjectById("dsRiskManager_Risks");
</script>
